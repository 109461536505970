const G6 = require('@antv/g6/src')
const Grid = require('@antv/g6/plugins/grid')
const Minimap = require('@antv/g6/plugins/minimap')
const SingleShapeMixin = require('@antv/g6/src/shape/single-shape-mixin');
const Item = require('@antv/g6/src/item/item');

module.exports = (window as any).G6 = {
    default: G6,
    Grid,
    Minimap,
    SingleShapeMixin,
    Item,
    version: '@0.1.4',
}
